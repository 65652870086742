import React from "react";

const pricingData = [
  {
    first: (
      <ul>
        <li>Robust analytics dashboard</li>
        <li>Comprehensive CMS</li>
        <li>Add multiple product categories</li>
        <li>Add product subcategories</li>
        <li>Manage inventory</li>
        <li>Order history</li>
        <li>Reviews & ratings</li>
        <li>Website layout</li>
        <li>Cross platform mobile apps</li>
        <li>No additional set up fee</li>
        <li>Full-featured online store</li>
      </ul>
    ),
    second: (
      <ul>
        <li>24/7 chat and email support</li>
        <li>Unlimited product catalog</li>
        <li>Coupons, and discounts</li>
        <li>Shipping charges</li>
        <li>Fully customizable checkout</li>
        <li>Multi-Storefront</li>
        <li>Search functionality</li>
        <li>Abandoned cart saver</li>
        <li>Product filtering</li>
        <li>Customized store fronts</li>
        <li>Secure payment gateways</li>
      </ul>
    ),
  },
];

export default pricingData;
