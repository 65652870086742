import React from "react";
import Layout from "../Components/Common/Layout";
import HeroSection from "../Components/Common/HeroSection";
import heroImage from "../images/pricingHeroImage.svg";
import PricingFeatures from "../Components/PricingPage/PricingFeatures";
import Questions from "../Components/Common/Questions";
import EmailForm from "../Components/Common/EmailForm";

const Pricing = () => {
  return (
    <Layout>
      <HeroSection
        title="Experience More for Less"
        subTitle="We strive to provide maximum value for your investment and understand that finding the right pricing plan can be challenging, which is why our plans are simple, transparent, and affordable."
        buttonText="Request Pricing"
        image={heroImage}
        pageName="pricing"
      />
      <PricingFeatures />
      <Questions />
      <EmailForm />
    </Layout>
  );
};

export default Pricing;
