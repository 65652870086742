import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import pricingData from "../../Data/PricingData";
import * as styles from "../../styles/PricingPage/PricingFeatures.module.scss";
import { Link } from "gatsby";

const PricingFeatures = () => {
  return (
    <Box className={styles.pricingFeatureWrapper}>
      <Container>
        <Box className={styles.pricingFeatureContent}>
          <Typography variant="h2">Feature-Packed and Ready to Use</Typography>
          <Typography paragraph={true}>
            See what Quickxi can do for you to upgrade your experience. 14-days
            free trial available.
          </Typography>
        </Box>
        <Box className={styles.pricingFeatureListWrapper}>
          {pricingData.map((content, index) => {
            return (
              <>
                <Box className={styles.pricingFeatureList} key={index}>
                  {content.first}
                </Box>
                <Box className={styles.pricingFeatureList} key={index}>
                  {content.second}
                </Box>
              </>
            );
          })}
        </Box>
        <Box className={styles.pricingFeatureButton}>
          <Link to="/contact-us/">
            <Button>Request Pricing</Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingFeatures;
